import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
 
  {
    path: '/',
    meta:{
      prefix:'web'
    },
    component: () => import('../views/Web/Plantilla.vue'),
    redirect:{name:'inicioWeb'},
    children:[
      {
        path: '',
        name: 'inicioWeb',
        meta:{
          prefix:'web'
        },
        component: () => import('../views/Web/Inicio/Index.vue')
      },
      {
        path: '/productos',
        name: 'productosWeb',
        meta:{
          prefix:'web'
        },
        component: () => import('../views/Web/Productos/Index.vue')
      },
      {
        path: '/blog',
        name: 'blogWeb',
        meta:{
          prefix:'web'
        },
        component: () => import('../views/Web/Blog/Index.vue')
      }
    ]
  },

  

  {
    path: '/panel/login',
    name: 'loginPanel',
    meta:{
      prefix:'panel'
    },
    component: () => import('../views/Panel/Auth/Login.vue')
  },

  {
    path: '/panel',
    meta:{
      autenticado:true,
      prefix:'panel'
    },
    component: () => import('../views/Panel/Plantilla.vue'),
    redirect:{name:'inicioPanel'},
    children:[
      {
        path: 'inicio',
        name: 'inicioPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Inicio/Index.vue')
      },
      {
        path: 'usuarios',
        name: 'usuariosPanel',
        meta:{
          autenticado:true,
          prefix:'panel'
        },
        component: () => import('../views/Panel/Usuarios/Index.vue')
      }
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router

<template>
<div id="app">
    <router-view />
</div>
</template>

<script>
export default {
    name: 'App',
    metaInfo() {
        return {
            title: "Epiloge - Build your network in your field of interest",
            meta: [{
                    name: 'description',
                    content: 'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'
                },
                {
                    property: 'og:title',
                    content: "Epiloge - Build your network in your field of interest"
                },
                {
                    property: 'og:site_name',
                    content: 'Epiloge'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    name: 'robots',
                    content: 'index,follow'
                }
            ]
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>

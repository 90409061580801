import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSession from 'vue-session'
import VueMeta from 'vue-meta'
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.config.productionTip = false;



Vue.use(Vuetify)
Vue.use(VueSession)
Vue.use(VueMeta)

//configuracion axios
axios.interceptors.request.use(config => {
  config.baseURL = process.env.VUE_APP_URL_API;
  config.headers.common['Authorization'] = 'Bearer '+ Vue.prototype.$session.get('token');
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

Vue.use(VueAxios, axios)
//configuracion axios



//protegiendo rutas
router.beforeEach((to, from, next) => {
  
  let token = Vue.prototype.$session.get('token');

  if (to.meta.prefix === 'panel') {

    if (to.name !== 'loginPanel') {

      axios.post('api/panel/login/verificarToken')
      .then((res) => {

        Vue.prototype.$session.set('usuario',res.data.usuario);

        next();
      }).catch((err) => {

        if (err.response.status === 401) {
          Vue.prototype.$session.clear();
          next({name:'loginPanel'});
        }
        
      });
      
    }else{
 
        if (to.name === 'loginPanel' && token !== null &&  token !== undefined && token !== '') {
          next({name:'inicioPanel'});
        }else{
          next();
        }
      
    }
    
    
  }else{
    next();
  }

})
//protegiendo rutas

new Vue({
  vuetify:new Vuetify(),
  router,
  store,
  render: h => h(App)
}).$mount('#app')
